// 현재 사용하지 않는 리듀서. 관리자 업데이트 할 경우 사용할 예정
import { enableAllPlugins } from "immer";
import { produce } from "immer";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from "../utils/reducerUtils";
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export var initialState = { objectData: { name: "", title: "", description: "", originalImage: { path: "", url: "" }, thumbnailImage: { path: "", url: "" }, link: { isActive: false, title: "", url: "" }, like: { isActive: false, count: 0 }, frameThick: 0, height: 0, width: 0, isValied: true },
    getObjectDataStatus: createStatus() }; // 액션들의 집합.
export var actions = { GET_OBJECT_DATA_REQUEST: "GET_OBJECT_DATA_REQUEST", GET_OBJECT_DATA_SUCCESS: "GET_OBJECT_DATA_SUCCESS", GET_OBJECT_DATA_FAILURE: "GET_OBJECT_DATA_FAILURE", RESET_OBJECT_DATA: "RESET_OBJECT_DATA" }; // 액션 크리에이터. dispatch 내부에서 사용.
export var getObjectDataAction = function getObjectDataAction(objectType, collectionId, id) { return { type: actions.GET_OBJECT_DATA_REQUEST, objectType: objectType, collectionId: collectionId, id: id }; };
export var resetObjectDataAction = function resetObjectDataAction() { return { type: actions.RESET_OBJECT_DATA }; }; // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
var reducer = function reducer() { var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; var action = arguments.length > 1 ? arguments[1] : undefined; return produce(state, function (draft) { switch (action.type) {
    case actions.GET_OBJECT_DATA_REQUEST:
        draft.getObjectDataStatus = createRequestStatus();
        break;
    case actions.GET_OBJECT_DATA_SUCCESS:
        draft.getObjectDataStatus = createSuccessStatus();
        draft.objectData = action.data;
        break;
    case actions.GET_OBJECT_DATA_FAILURE:
        draft.getObjectDataStatus = createFailureStatus(action.error);
        break;
    case actions.RESET_OBJECT_DATA:
        draft.objectData = { name: "", title: "", description: "", originalImage: { path: "", url: "" }, thumbnailImage: { path: "", url: "" }, link: { isActive: false, title: "", url: "" }, frameThick: 0, height: 0, width: 0, isValied: true, like: { isActive: false, count: 0 } };
        draft.getObjectDataStatus = resetStatus();
        break;
    default: break;
} }); };
export default reducer;
