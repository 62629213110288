import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
function useWidth() { var theme = useTheme(); var greaterThanMid = useMediaQuery(theme.breakpoints.up("md")); var smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md")); var xsToSmall = useMediaQuery(theme.breakpoints.between("xs", "sm")); var lessThanXS = useMediaQuery(theme.breakpoints.down("xs")); if (greaterThanMid) {
    return "lg";
}
else if (smallToMid) {
    return "md";
}
else if (xsToSmall) {
    return "sm";
}
else if (lessThanXS) {
    return "xs";
}
else {
    return "xl";
} }
export default useWidth;
