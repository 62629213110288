import { enableAllPlugins } from "immer";
import { produce } from "immer";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus } from "../utils/reducerUtils";
enableAllPlugins(); // 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export var initialState = { title: "", url: "", copyRightMessage: "",
    getMusicStatus: createStatus() }; // 액션들의 집합.
export var actions = { GET_MUSIC_REQUEST: "GET_MUSIC_REQUEST", GET_MUSIC_SUCCESS: "GET_MUSIC_SUCCESS", GET_MUSIC_FAILURE: "GET_MUSIC_FAILURE" }; // 액션 크리에이터. dispatch 내부에서 사용.
export var getMusicAction = function getMusicAction(id) { return { type: actions.GET_MUSIC_REQUEST, id: id }; }; // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
var reducer = function reducer() { var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState; var action = arguments.length > 1 ? arguments[1] : undefined; return produce(state, function (draft) { switch (action.type) {
    case actions.GET_MUSIC_REQUEST:
        draft.getMusicStatus = createRequestStatus();
        break;
    case actions.GET_MUSIC_SUCCESS:
        draft.getMusicStatus = createSuccessStatus();
        draft.title = action.data.title;
        draft.url = action.data.url;
        draft.copyRightMessage = action.data.copyRightMessage;
        break;
    case actions.GET_MUSIC_FAILURE:
        draft.getMusicStatus = createFailureStatus(action.error);
        break;
    default: break;
} }); };
export default reducer;
