import { enableAllPlugins } from "immer";
import { produce } from "immer";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from "../utils/reducerUtils";
import firebase from "firebase";
enableAllPlugins(); // Exhibition 데이터의 상태 기본값. 데이터를 갖고 오기 전
export var initialState = { exhibitionData: { id: "", title: "", description: "", owner: "", author: "", showcase: "", space: {}, musicId: "", hasLikeButton: false, hasLinkButton: false, hasObjectChat: false, effectFXAA: false, isEnded: false, objectLikeNum: 0, views: { todayView: 0, totalView: 0, currentView: 0 }, todayVisitedIP: [], originalPosterImage: { url: "", path: "" }, compressedPosterImage: { url: "", path: "" }, thumbnailPosterImage: { url: "", path: "" }, musicTitle: "", isPlatform: true, createdAt: null, updatedAt: null, publishedAt: 0, paidAt: null, expiredAt: null, like: 0, likedIPs: [], blockViewCount: false, plan: "business" },
    selectedObject: { id: "", name: "", title: "", description: "", thumbnailImageUrl: "", originalImageUrl: "", compressedImageUrl: "", compressedImageUrl_1600: "",
        value: "" }, hoveredObject: { id: "", title: "", type: "none" }, getObjectDataStatus: createStatus(), syncExhibitionDataStatus: createStatus(), getExhibitionDataStatus: createStatus(), updateExhibitionDataStatus: createStatus(), toggleLikeButtonStatus: createStatus(), toggleLikeExhibitionButtonStatus: createStatus(), countViewStatus: createStatus() }; // 액션들의 집합.
export var actions = { SYNC_EXHIBITION_DATA_REQUEST: "SYNC_EXHIBITION_DATA_REQUEST", SYNC_EXHIBITION_DATA_SUCCESS: "SYNC_EXHIBITION_DATA_SUCCESS", SYNC_EXHIBITION_DATA_FAILURE: "SYNC_EXHIBITION_DATA_FAILURE", GET_EXHIBITION_DATA_REQUEST: "GET_EXHIBITION_DATA_REQUEST", GET_EXHIBITION_DATA_SUCCESS: "GET_EXHIBITION_DATA_SUCCESS", GET_EXHIBITION_DATA_FAILURE: "GET_EXHIBITION_DATA_FAILURE", UPDATE_EXHIBITION_DATA_REQUEST: "UPDATE_EXHIBITION_DATA_REQUEST", UPDATE_EXHIBITION_DATA_SUCCESS: "UPDATE_EXHIBITION_DATA_SUCCESS", UPDATE_EXHIBITION_DATA_FAILURE: "UPDATE_EXHIBITION_DATA_FAILURE", GET_OBJECT_DATA_REQUEST: "GET_OBJECT_DATA_REQUEST", GET_OBJECT_DATA_SUCCESS: "GET_OBJECT_DATA_SUCCESS", GET_OBJECT_DATA_FAILURE: "GET_OBJECT_DATA_FAILURE", SET_CURRENT_OBJECT_VALUE: "SET_CURRENT_OBJECT_VALUE", RESET_OBJECT_DATA: "RESET_OBJECT_DATA", UPDATE_EXHIBITION_DATA_RESET: "UPDATE_EXHIBITION_DATA_RESET", SYNC_EXHIBITION_DATA_DONE: "SYNC_EXHIBITION_DATA_DONE", TOGGLE_LIKE_BUTTON_REQUEST: "TOGGLE_LIKE_BUTTON_REQUEST", TOGGLE_LIKE_BUTTON_SUCCESS: "TOGGLE_LIKE_BUTTON_SUCCESS", TOGGLE_LIKE_BUTTON_FAILURE: "TOGGLE_LIKE_BUTTON_FAILURE", TOGGLE_LIKE_EXHIBITION_BUTTON_REQUEST: "TOGGLE_LIKE_EXHIBITION_BUTTON_REQUEST", TOGGLE_LIKE_EXHIBITION_BUTTON_SUCCESS: "TOGGLE_LIKE_EXHIBITION_BUTTON_SUCCESS", TOGGLE_LIKE_EXHIBITION_BUTTON_FAILURE: "TOGGLE_LIKE_EXHIBITION_BUTTON_FAILURE", COUNT_VIEW_REQUEST: "COUNT_VIEW_REQUEST", COUNT_VIEW_SUCCESS: "COUNT_VIEW_SUCCESS", COUNT_VIEW_FAILURE: "COUNT_VIEW_FAILURE", PLUS_CURRENT_VIEW_REQUEST: "PLUS_CURRENT_VIEW_REQUEST", PLUS_CURRENT_VIEW_SUCCESS: "PLUS_CURRENT_VIEW_SUCCESS", PLUS_CURRENT_VIEW_FAILURE: "PLUS_CURRENT_VIEW_FAILURE", MINUS_CURRENT_VIEW_REQUEST: "MINUS_CURRENT_VIEW_REQUEST", MINUS_CURRENT_VIEW_SUCCESS: "MINUS_CURRENT_VIEW_SUCCESS", MINUS_CURRENT_VIEW_FAILURE: "MINUS_CURRENT_VIEW_FAILURE", SET_HOVERED_OBJECT: "SET_HOVERED_OBJECT" }; // 액션 크리에이터. dispatch 내부에서 사용.
// 오브젝트(이미지)의 데이터를 불러옴.
export var getObjectDataAction = function getObjectDataAction(objectType, exhibitionId, id) { return { type: actions.GET_OBJECT_DATA_REQUEST, objectType: objectType, exhibitionId: exhibitionId, id: id }; }; // 오브젝트(이미지)의 데이터를 초기화.
export var resetObjectDataAction = function resetObjectDataAction() { return { type: actions.RESET_OBJECT_DATA }; };
export var setHoveredObject = function setHoveredObject(payload) { return { type: actions.SET_HOVERED_OBJECT, payload: payload }; };
export var syncExhibitionDataAction = function syncExhibitionDataAction(id) {
    return {
        type: actions.SYNC_EXHIBITION_DATA_REQUEST, id: id
    };
};
export var getExhibitionDataAction = function getExhibitionDataAction(id) {
    return {
        type: actions.GET_EXHIBITION_DATA_REQUEST, id: id
    };
}; // 전시회 데이터 업데이트
export var updateExhibitionDataAction = function updateExhibitionDataAction(id, target, value) { return { type: actions.GET_EXHIBITION_DATA_REQUEST, id: id, target: target, value: value }; }; // 전시회 데이터 싱크 종료
export var syncExhibitionDataDoneAction = function syncExhibitionDataDoneAction() { return { type: actions.SYNC_EXHIBITION_DATA_DONE }; }; // 좋아요 버튼 실행 및 실행 취소
export var toggleLikeButtonAction = function toggleLikeButtonAction(id, target, isLiked, ip) { var objectType = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "Paintings"; return { type: actions.TOGGLE_LIKE_BUTTON_REQUEST, id: id, target: target, isLiked: isLiked, ip: ip, objectType: objectType }; }; // 전시회 좋아요 버튼 실행 및 실행 취소
export var toggleExhibitionLikeButtonAction = function toggleExhibitionLikeButtonAction(id, ip) { return { type: actions.TOGGLE_LIKE_EXHIBITION_BUTTON_REQUEST, id: id, ip: ip }; }; // 뷰 +1 올려줌. 하루 뷰 초기화는 클라우드 펑션을 실행.
export var countViewAction = function countViewAction(id, ip) { return { type: actions.COUNT_VIEW_REQUEST, id: id, ip: ip }; }; // 현재 접속자수 +1
export var plusCurrentViewAction = function plusCurrentViewAction(id, randomKey) { return { type: actions.PLUS_CURRENT_VIEW_REQUEST, id: id, randomKey: randomKey }; };
export var setCurrentObjectValue = function setCurrentObjectValue(value) { var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "value"; return { type: actions.SET_CURRENT_OBJECT_VALUE, value: value, target: target }; }; // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
var reducer = function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return produce(state, function (draft) {
        var _action$data$title$ko, _action$data$title, _action$data$author$k, _action$data$author, _action$data$space$ma, _action$data$space, _action$data$space$ma2, _action$data$objectLi, _action$data$effectFX, _action$data$views, _action$data$views2, _action$data$todayVis, _action$data$views$to, _action$data$views3, _action$data$currentV, _action$data$todayVis2, _action$data$isPlatfo, _action$data$like, _action$data$likedIPs, _action$data$blockVie, _action$data$isEnded, _ref, _action$data$title$ko2, _action$data$title2, _ref2, _action$data$author$k2, _action$data$author2, _action$data$space$ma3, _action$data$space2, _action$data$space2$m, _action$data$objectLi2, _action$data$effectFX2, _action$data$views$to2, _action$data$views4, _action$data$views$to3, _action$data$views5, _action$data$currentV2, _action$data$todayVis3, _action$data$isPlatfo2, _action$data$like2, _action$data$likedIPs2, _action$data$blockVie2, _action$data$isEnded2, _action$data$name, _action$data$title3, _action$data$descript, _action$data$width, _action$data$height, _action$data$original, _action$data$original2, _action$data$thumbnai, _action$data$thumbnai2, _action$data$compress, _action$data$compress2, _ref3, _action$data$compress3, _action$data$compress4, _action$data$compress5, _action$data$author3, _action$data$link, _action$data$links, _action$data$like3, _action$data$likedIPs3, _action$data$youtubeL, _action$data$videoTyp, _action$data$playType, _action$data$videoUrl;
        switch (action.type) {
            case actions.SYNC_EXHIBITION_DATA_REQUEST:
                draft.syncExhibitionDataStatus = createRequestStatus();
                break;
            case actions.SYNC_EXHIBITION_DATA_SUCCESS:
                draft.syncExhibitionDataStatus = createSuccessStatus();
                draft.exhibitionData.id = action.id;
                draft.exhibitionData.title = (_action$data$title$ko = (_action$data$title = action.data.title) === null || _action$data$title === void 0 ? void 0 : _action$data$title["ko-KR"]) !== null && _action$data$title$ko !== void 0 ? _action$data$title$ko : "";
                draft.exhibitionData.author = (_action$data$author$k = (_action$data$author = action.data.author) === null || _action$data$author === void 0 ? void 0 : _action$data$author["ko-KR"]) !== null && _action$data$author$k !== void 0 ? _action$data$author$k : "";
                draft.exhibitionData.description = action.data.description;
                draft.exhibitionData.showcase = (_action$data$space$ma = (_action$data$space = action.data.space) === null || _action$data$space === void 0 ? void 0 : (_action$data$space$ma2 = _action$data$space.matterportId) === null || _action$data$space$ma2 === void 0 ? void 0 : _action$data$space$ma2.trim()) !== null && _action$data$space$ma !== void 0 ? _action$data$space$ma : "";
                draft.exhibitionData.musicId = action.data.musicId;
                draft.exhibitionData.objectLikeNum = (_action$data$objectLi = action.data.objectLikeNum) !== null && _action$data$objectLi !== void 0 ? _action$data$objectLi : 0;
                draft.exhibitionData.effectFXAA = (_action$data$effectFX = action.data.effectFXAA) !== null && _action$data$effectFX !== void 0 ? _action$data$effectFX : false;
                draft.exhibitionData.views.todayView = ((_action$data$views = action.data.views) === null || _action$data$views === void 0 ? void 0 : _action$data$views.todayView) > 100 ? (_action$data$views2 = action.data.views) === null || _action$data$views2 === void 0 ? void 0 : _action$data$views2.todayView : (_action$data$todayVis = action.data.todayVisitedIP) === null || _action$data$todayVis === void 0 ? void 0 : _action$data$todayVis.length;
                draft.exhibitionData.views.totalView = (_action$data$views$to = (_action$data$views3 = action.data.views) === null || _action$data$views3 === void 0 ? void 0 : _action$data$views3.totalView) !== null && _action$data$views$to !== void 0 ? _action$data$views$to : 0;
                var currentViews = (_action$data$currentV = action.data.currentViews) !== null && _action$data$currentV !== void 0 ? _action$data$currentV : {};
                draft.exhibitionData.views.currentView = Object.keys(currentViews).length;
                draft.exhibitionData.todayVisitedIP = (_action$data$todayVis2 = action.data.todayVisitedIP) !== null && _action$data$todayVis2 !== void 0 ? _action$data$todayVis2 : [];
                draft.exhibitionData.originalPosterImage = action.data.originalPosterImage;
                draft.exhibitionData.compressedPosterImage = action.data.compressedPosterImage;
                draft.exhibitionData.thumbnailPosterImage = action.data.thumbnailPosterImage;
                draft.exhibitionData.musicTitle = action.data.musicTitle;
                draft.exhibitionData.isPlatform = (_action$data$isPlatfo = action.data.isPlatform) !== null && _action$data$isPlatfo !== void 0 ? _action$data$isPlatfo : false;
                draft.exhibitionData.createdAt = action.data.createdAt;
                draft.exhibitionData.updatedAt = action.data.updatedAt;
                draft.exhibitionData.publishedAt = action.data.publishedAt;
                draft.exhibitionData.paidAt = action.data.paidAt;
                draft.exhibitionData.expiredAt = action.data.expiredAt;
                draft.exhibitionData.like = (_action$data$like = action.data.like) !== null && _action$data$like !== void 0 ? _action$data$like : 0;
                draft.exhibitionData.likedIPs = (_action$data$likedIPs = action.data.likedIPs) !== null && _action$data$likedIPs !== void 0 ? _action$data$likedIPs : [];
                draft.exhibitionData.blockViewCount = (_action$data$blockVie = action.data.blockViewCount) !== null && _action$data$blockVie !== void 0 ? _action$data$blockVie : false;
                draft.exhibitionData.isEnded = (_action$data$isEnded = action.data.isEnded) !== null && _action$data$isEnded !== void 0 ? _action$data$isEnded : false;
                draft.exhibitionData.plan = "business";
                draft.exhibitionData.hasLikeButton = true;
                draft.exhibitionData.hasObjectChat = true;
                draft.exhibitionData.hasLinkButton = true;
                if (action.data.isPlatform) {
                    var _action$data$expiredA;
                    draft.exhibitionData.plan = action.data.plan;
                    draft.exhibitionData.hasLikeButton = true;
                    draft.exhibitionData.hasObjectChat = true;
                    if (action.data.plan === "business" && ((_action$data$expiredA = action.data.expiredAt) === null || _action$data$expiredA === void 0 ? void 0 : _action$data$expiredA.seconds) > firebase.firestore.Timestamp.now().seconds) {
                        draft.exhibitionData.hasLinkButton = true;
                    }
                    else {
                        draft.exhibitionData.hasLinkButton = false;
                    } // if (
                    //   !action.data.plan ||
                    //   action.data.plan === "free" ||
                    //   action.data.expiredAt?.seconds < firebase.firestore.Timestamp.now().seconds
                    // ) {
                    //   draft.exhibitionData.hasLinkButton = false;
                    //   draft.exhibitionData.hasLikeButton = false;
                    //   draft.exhibitionData.hasObjectChat = true;
                    // }
                    if (action.data.isCustomized) {
                        draft.exhibitionData.hasLikeButton = action.data.hasLikeButton;
                        draft.exhibitionData.hasLinkButton = action.data.hasLinkButton;
                        draft.exhibitionData.hasObjectChat = action.data.hasObjectChat;
                    }
                }
                break;
            case actions.SYNC_EXHIBITION_DATA_FAILURE:
                draft.syncExhibitionDataStatus = createFailureStatus(action.error);
                break;
            case actions.GET_EXHIBITION_DATA_REQUEST:
                draft.getExhibitionDataStatus = createRequestStatus();
                break;
            case actions.GET_EXHIBITION_DATA_SUCCESS:
                draft.getExhibitionDataStatus = createSuccessStatus();
                draft.exhibitionData.id = action.id;
                draft.exhibitionData.title = (_ref = (_action$data$title$ko2 = (_action$data$title2 = action.data.title) === null || _action$data$title2 === void 0 ? void 0 : _action$data$title2["ko-KR"]) !== null && _action$data$title$ko2 !== void 0 ? _action$data$title$ko2 : action.data.title) !== null && _ref !== void 0 ? _ref : "";
                draft.exhibitionData.author = (_ref2 = (_action$data$author$k2 = (_action$data$author2 = action.data.author) === null || _action$data$author2 === void 0 ? void 0 : _action$data$author2["ko-KR"]) !== null && _action$data$author$k2 !== void 0 ? _action$data$author$k2 : action.data.author) !== null && _ref2 !== void 0 ? _ref2 : "";
                draft.exhibitionData.description = action.data.description;
                draft.exhibitionData.showcase = (_action$data$space$ma3 = (_action$data$space2 = action.data.space) === null || _action$data$space2 === void 0 ? void 0 : (_action$data$space2$m = _action$data$space2.matterportId) === null || _action$data$space2$m === void 0 ? void 0 : _action$data$space2$m.trim()) !== null && _action$data$space$ma3 !== void 0 ? _action$data$space$ma3 : "";
                draft.exhibitionData.musicId = action.data.musicId;
                draft.exhibitionData.objectLikeNum = (_action$data$objectLi2 = action.data.objectLikeNum) !== null && _action$data$objectLi2 !== void 0 ? _action$data$objectLi2 : 0;
                draft.exhibitionData.effectFXAA = (_action$data$effectFX2 = action.data.effectFXAA) !== null && _action$data$effectFX2 !== void 0 ? _action$data$effectFX2 : false;
                draft.exhibitionData.views.todayView = (_action$data$views$to2 = (_action$data$views4 = action.data.views) === null || _action$data$views4 === void 0 ? void 0 : _action$data$views4.todayView) !== null && _action$data$views$to2 !== void 0 ? _action$data$views$to2 : 0;
                draft.exhibitionData.views.totalView = (_action$data$views$to3 = (_action$data$views5 = action.data.views) === null || _action$data$views5 === void 0 ? void 0 : _action$data$views5.totalView) !== null && _action$data$views$to3 !== void 0 ? _action$data$views$to3 : 0;
                var currentView = (_action$data$currentV2 = action.data.currentViews) !== null && _action$data$currentV2 !== void 0 ? _action$data$currentV2 : {};
                draft.exhibitionData.views.currentView = Object.keys(currentView).length;
                draft.exhibitionData.todayVisitedIP = (_action$data$todayVis3 = action.data.todayVisitedIP) !== null && _action$data$todayVis3 !== void 0 ? _action$data$todayVis3 : [];
                draft.exhibitionData.originalPosterImage = action.data.originalPosterImage;
                draft.exhibitionData.compressedPosterImage = action.data.compressedPosterImage;
                draft.exhibitionData.thumbnailPosterImage = action.data.thumbnailPosterImage;
                draft.exhibitionData.musicTitle = action.data.musicTitle;
                draft.exhibitionData.isPlatform = (_action$data$isPlatfo2 = action.data.isPlatform) !== null && _action$data$isPlatfo2 !== void 0 ? _action$data$isPlatfo2 : false;
                draft.exhibitionData.createdAt = action.data.createdAt;
                draft.exhibitionData.updatedAt = action.data.updatedAt;
                draft.exhibitionData.publishedAt = action.data.publishedAt;
                draft.exhibitionData.paidAt = action.data.paidAt;
                draft.exhibitionData.expiredAt = action.data.expiredAt;
                draft.exhibitionData.like = (_action$data$like2 = action.data.like) !== null && _action$data$like2 !== void 0 ? _action$data$like2 : 0;
                draft.exhibitionData.likedIPs = (_action$data$likedIPs2 = action.data.likedIPs) !== null && _action$data$likedIPs2 !== void 0 ? _action$data$likedIPs2 : [];
                draft.exhibitionData.blockViewCount = (_action$data$blockVie2 = action.data.blockViewCount) !== null && _action$data$blockVie2 !== void 0 ? _action$data$blockVie2 : false;
                draft.exhibitionData.isEnded = (_action$data$isEnded2 = action.data.isEnded) !== null && _action$data$isEnded2 !== void 0 ? _action$data$isEnded2 : false;
                draft.exhibitionData.plan = "business";
                draft.exhibitionData.hasLikeButton = true;
                draft.exhibitionData.hasObjectChat = true;
                draft.exhibitionData.hasLinkButton = true;
                if (action.data.isPlatform) {
                    var _action$data$expiredA2;
                    if (action.data.plan === "business" && ((_action$data$expiredA2 = action.data.expiredAt) === null || _action$data$expiredA2 === void 0 ? void 0 : _action$data$expiredA2.seconds) > firebase.firestore.Timestamp.now().seconds) {
                        draft.exhibitionData.hasLinkButton = true;
                    }
                    else {
                        draft.exhibitionData.hasLinkButton = false;
                    } // if (
                    //   !action.data.plan ||
                    //   action.data.plan === "free" ||
                    //   action.data.expiredAt?.seconds < firebase.firestore.Timestamp.now().seconds
                    // ) {
                    //   draft.exhibitionData.hasLinkButton = false;
                    //   draft.exhibitionData.hasLikeButton = false;
                    //   draft.exhibitionData.hasObjectChat = true;
                    // }
                    if (action.data.isCustomized) {
                        draft.exhibitionData.hasLikeButton = action.data.hasLikeButton;
                        draft.exhibitionData.hasLinkButton = action.data.hasLinkButton;
                        draft.exhibitionData.hasObjectChat = action.data.hasObjectChat;
                    }
                }
                break;
            case actions.GET_EXHIBITION_DATA_FAILURE:
                draft.getExhibitionDataStatus = createFailureStatus(action.error);
                break;
            case actions.UPDATE_EXHIBITION_DATA_REQUEST:
                draft.updateExhibitionDataStatus = createRequestStatus();
                break;
            case actions.UPDATE_EXHIBITION_DATA_SUCCESS:
                draft.updateExhibitionDataStatus = createSuccessStatus();
                break;
            case actions.UPDATE_EXHIBITION_DATA_FAILURE:
                draft.updateExhibitionDataStatus = createFailureStatus(action.error);
                break;
            case actions.UPDATE_EXHIBITION_DATA_RESET:
                draft.updateExhibitionDataStatus = resetStatus();
                break;
            case actions.TOGGLE_LIKE_BUTTON_REQUEST:
                draft.toggleLikeButtonStatus = createRequestStatus();
                break;
            case actions.TOGGLE_LIKE_BUTTON_SUCCESS:
                draft.toggleLikeButtonStatus = createSuccessStatus();
                break;
            case actions.TOGGLE_LIKE_BUTTON_FAILURE:
                draft.toggleLikeButtonStatus = createFailureStatus(action.error);
                break;
            case actions.SET_CURRENT_OBJECT_VALUE:
                draft.selectedObject[action.target] = action.value;
                break;
            case actions.TOGGLE_LIKE_EXHIBITION_BUTTON_REQUEST:
                draft.toggleLikeExhibitionButtonStatus = createRequestStatus();
                break;
            case actions.TOGGLE_LIKE_EXHIBITION_BUTTON_SUCCESS:
                draft.toggleLikeExhibitionButtonStatus = createSuccessStatus();
                break;
            case actions.TOGGLE_LIKE_EXHIBITION_BUTTON_FAILURE:
                draft.toggleLikeExhibitionButtonStatus = createFailureStatus(action.error);
                break;
            case actions.GET_OBJECT_DATA_REQUEST:
                draft.getObjectDataStatus = createRequestStatus();
                break;
            case actions.GET_OBJECT_DATA_SUCCESS:
                draft.getObjectDataStatus = createSuccessStatus();
                if (!action.data)
                    break;
                draft.selectedObject.id = action.id;
                draft.selectedObject.name = (_action$data$name = action.data.name) !== null && _action$data$name !== void 0 ? _action$data$name : "";
                draft.selectedObject.title = (_action$data$title3 = action.data.title) !== null && _action$data$title3 !== void 0 ? _action$data$title3 : "";
                draft.selectedObject.description = (_action$data$descript = action.data.description) !== null && _action$data$descript !== void 0 ? _action$data$descript : "";
                draft.selectedObject.width = (_action$data$width = action.data.width) !== null && _action$data$width !== void 0 ? _action$data$width : 0;
                draft.selectedObject.height = (_action$data$height = action.data.height) !== null && _action$data$height !== void 0 ? _action$data$height : 0;
                draft.selectedObject.originalImageUrl = (_action$data$original = (_action$data$original2 = action.data.originalImage) === null || _action$data$original2 === void 0 ? void 0 : _action$data$original2.url) !== null && _action$data$original !== void 0 ? _action$data$original : "";
                draft.selectedObject.thumbnailImageUrl = (_action$data$thumbnai = (_action$data$thumbnai2 = action.data.thumbnailImage) === null || _action$data$thumbnai2 === void 0 ? void 0 : _action$data$thumbnai2.url) !== null && _action$data$thumbnai !== void 0 ? _action$data$thumbnai : "";
                draft.selectedObject.compressedImageUrl = (_action$data$compress = (_action$data$compress2 = action.data.compressedImage) === null || _action$data$compress2 === void 0 ? void 0 : _action$data$compress2.url) !== null && _action$data$compress !== void 0 ? _action$data$compress : "";
                draft.selectedObject.compressedImageUrl_1600 = (_ref3 = (_action$data$compress3 = (_action$data$compress4 = action.data.compressedImageUrl_1600) === null || _action$data$compress4 === void 0 ? void 0 : _action$data$compress4.url) !== null && _action$data$compress3 !== void 0 ? _action$data$compress3 : (_action$data$compress5 = action.data.compressedImage) === null || _action$data$compress5 === void 0 ? void 0 : _action$data$compress5.url) !== null && _ref3 !== void 0 ? _ref3 : "";
                draft.selectedObject.author = (_action$data$author3 = action.data.author) !== null && _action$data$author3 !== void 0 ? _action$data$author3 : "";
                draft.selectedObject.link = (_action$data$link = action.data.link) !== null && _action$data$link !== void 0 ? _action$data$link : { isActive: false, title: "", url: "" };
                draft.selectedObject.links = (_action$data$links = action.data.links) !== null && _action$data$links !== void 0 ? _action$data$links : [];
                draft.selectedObject.like = (_action$data$like3 = action.data.like) !== null && _action$data$like3 !== void 0 ? _action$data$like3 : { count: 0 };
                draft.selectedObject.likedIPs = (_action$data$likedIPs3 = action.data.likedIPs) !== null && _action$data$likedIPs3 !== void 0 ? _action$data$likedIPs3 : [];
                draft.selectedObject.youtubeLink = (_action$data$youtubeL = action.data.youtubeLink) !== null && _action$data$youtubeL !== void 0 ? _action$data$youtubeL : "";
                draft.selectedObject.videoType = (_action$data$videoTyp = action.data.videoType) !== null && _action$data$videoTyp !== void 0 ? _action$data$videoTyp : "";
                draft.selectedObject.playType = (_action$data$playType = action.data.playType) !== null && _action$data$playType !== void 0 ? _action$data$playType : "";
                draft.selectedObject.videoUrl = (_action$data$videoUrl = action.data.videoUrl) !== null && _action$data$videoUrl !== void 0 ? _action$data$videoUrl : "";
                break;
            case actions.GET_OBJECT_DATA_FAILURE:
                draft.getObjectDataStatus = createFailureStatus(action.error);
                break; // 오브젝트(그림, 동영상 등) 리셋
            case actions.RESET_OBJECT_DATA:
                draft.selectedObject = { id: "", name: "", title: "", description: "", originalImageUrl: "", thumbnailImageUrl: "", compressedImageUrl: "", compressedImageUrl_1600: "", value: "" };
                draft.getObjectDataStatus = resetStatus();
                break; // 뷰 카운트 + 1
            case actions.COUNT_VIEW_REQUEST:
                draft.updateExhibitionDataStatus = createRequestStatus();
                break;
            case actions.COUNT_VIEW_SUCCESS:
                draft.updateExhibitionDataStatus = createSuccessStatus();
                break;
            case actions.COUNT_VIEW_FAILURE:
                draft.updateExhibitionDataStatus = createFailureStatus(action.error);
                break; // 접속자수 +1
            case actions.PLUS_CURRENT_VIEW_REQUEST:
                draft.updateExhibitionDataStatus = createRequestStatus();
                break;
            case actions.PLUS_CURRENT_VIEW_SUCCESS:
                draft.updateExhibitionDataStatus = createSuccessStatus();
                break;
            case actions.PLUS_CURRENT_VIEW_FAILURE:
                draft.updateExhibitionDataStatus = createFailureStatus(action.error);
                break; // hover되었을 때 모델 데이터를 저장함.
            case actions.SET_HOVERED_OBJECT:
                draft.hoveredObject = { id: action.payload.id, title: action.payload.title, type: action.payload.type };
                break;
            default: break;
        }
    });
};
export default reducer;
