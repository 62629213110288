import { combineReducers } from "@reduxjs/toolkit"; // 사용자 리듀서(직접 만든 것들)
import global from "./global";
import music from "./music";
import chat from "./chat";
import exhibition from "./exhibition";
import collection from "./collection";
var rootReducer = combineReducers({
    global: global, music: music,
    chatting: chat, exhibition: exhibition, collection: collection
});
export default rootReducer;
