import { enableAllPlugins } from "immer";
import { produce } from "immer";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from "../utils/reducerUtils";
enableAllPlugins(); // 채팅 데이터의 상태 기본값. 데이터를 갖고 오기 전
export var initialState = { chatLength: 0, currentChats: [], objectChats: [],
    currentUser: { name: "", password: "" },
    syncNumberOfChatsStatus: createStatus(), syncNumberOfObjectChatsStatus: createStatus(), postChatStatus: createStatus(), deleteChatStatus: createStatus(), postObjectChatStatus: createStatus(), deleteObjectChatStatus: createStatus() }; // 액션들의 집합.
export var actions = { SYNC_NUMBER_OF_CHAT_REQUEST: "SYNC_NUMBER_OF_CHAT_REQUEST", SYNC_NUMBER_OF_CHAT_SUCCESS: "SYNC_NUMBER_OF_CHAT_SUCCESS", SYNC_NUMBER_OF_CHAT_FAILURE: "SYNC_NUMBER_OF_CHAT_FAILURE", SYNC_NUMBER_OF_CHAT_DONE: "SYNC_NUMBER_OF_CHAT_DONE", POST_CHAT_REQUEST: "POST_CHAT_REQUEST", POST_CHAT_SUCCESS: "POST_CHAT_SUCCESS", POST_CHAT_FAILURE: "POST_CHAT_FAILURE", DELETE_CHAT_REQUEST: "DELETE_CHAT_REQUEST", DELETE_CHAT_SUCCESS: "DELETE_CHAT_SUCCESS", DELETE_CHAT_FAILURE: "DELETE_CHAT_FAILURE", POST_OBJECT_CHAT_REQUEST: "POST_OBJECT_CHAT_REQUEST", POST_OBJECT_CHAT_SUCCESS: "POST_OBJECT_CHAT_SUCCESS", POST_OBJECT_CHAT_FAILURE: "POST_OBJECT_CHAT_FAILURE", DELETE_OBJECT_CHAT_REQUEST: "DELETE_OBJECT_CHAT_REQUEST", DELETE_OBJECT_CHAT_SUCCESS: "DELETE_OBJECT_CHAT_SUCCESS", DELETE_OBJECT_CHAT_FAILURE: "DELETE_OBJECT_CHAT_FAILURE", LOAD_CHAT_NUMBER: "LOAD_CHAT_NUMBER", SYNC_NUMBER_OF_OBJECT_CHAT_REQUEST: "SYNC_NUMBER_OF_OBJECT_CHAT_REQUEST", SYNC_NUMBER_OF_OBJECT_CHAT_SUCCESS: "SYNC_NUMBER_OF_OBJECT_CHAT_SUCCESS", SYNC_NUMBER_OF_OBJECT_CHAT_FAILURE: "SYNC_NUMBER_OF_OBJECT_CHAT_FAILURE", SYNC_NUMBER_OF_OBJECT_CHAT_DONE: "SYNC_NUMBER_OF_OBJECT_CHAT_DONE", RESET_DELETE_CHAT_STATUS: "RESET_DELETE_CHAT_STATUS",
    SAVE_USER: "SAVE_USER", LOAD_USER: "LOAD_USER", SAVE_AVATAR_USER: "SAVE_AVATAR_USER", LOAD_AVATAR_USER: "LOAD_AVATAR_USER" }; // 액션 크리에이터. dispatch 내부에서 사용.
export var postChatAction = function postChatAction(id, data) { return { type: actions.POST_CHAT_REQUEST, id: id, data: data }; };
export var postObjectChatAction = function postObjectChatAction(id, target, data) { return { type: actions.POST_OBJECT_CHAT_REQUEST, id: id, target: target, data: data }; };
export var deleteChatAction = function deleteChatAction(channelId, commentId, password) { return { type: actions.DELETE_CHAT_REQUEST, channelId: channelId, commentId: commentId, password: password }; };
export var deleteObjectChatAction = function deleteObjectChatAction(channelId, targetId, commentId, password) { return { type: actions.DELETE_OBJECT_CHAT_REQUEST, channelId: channelId, targetId: targetId, commentId: commentId, password: password }; };
export var syncNumberOfObjectChatAction = function syncNumberOfObjectChatAction(id, target, limit) { return { type: actions.SYNC_NUMBER_OF_OBJECT_CHAT_REQUEST, id: id, target: target, limit: limit }; };
export var resetDeleteChatStatusAction = function resetDeleteChatStatusAction() { return { type: actions.RESET_DELETE_CHAT_STATUS }; };
export var loadUserAction = function loadUserAction() { return { type: actions.LOAD_USER }; };
export var loadChatNumberAction = function loadChatNumberAction(num) { return { type: actions.LOAD_CHAT_NUMBER, num: num }; };
export var syncNumberOfChatAction = function syncNumberOfChatAction(id, number) { return { type: actions.SYNC_NUMBER_OF_CHAT_REQUEST, id: id, number: number }; };
export var syncNumberOfChatDoneAction = function syncNumberOfChatDoneAction() { return { type: actions.SYNC_NUMBER_OF_CHAT_DONE }; };
export var syncNumberOfObjectChatDoneAction = function syncNumberOfObjectChatDoneAction() { return { type: actions.SYNC_NUMBER_OF_OBJECT_CHAT_DONE }; };
export var saveAvatarUserAction = function saveAvatarUserAction(name, avatarType) { return { type: actions.SAVE_AVATAR_USER, name: name, avatarType: avatarType }; }; // 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
var reducer = function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return produce(state, function (draft) {
        switch (action.type) { // 기본 방명록 채팅 동기화
            case actions.LOAD_CHAT_NUMBER:
                draft.chatLength = action.num;
                break;
            case actions.SYNC_NUMBER_OF_CHAT_REQUEST:
                draft.syncNumberOfChatsStatus = createRequestStatus();
                break;
            case actions.SYNC_NUMBER_OF_CHAT_SUCCESS:
                draft.syncNumberOfChatsStatus = createSuccessStatus();
                draft.currentChats = action.data;
                break;
            case actions.SYNC_NUMBER_OF_CHAT_FAILURE:
                draft.syncNumberOfChatsStatus = createFailureStatus(action.error);
                break; // 그림에 딸려있는 채팅 동기화
            case actions.SYNC_NUMBER_OF_OBJECT_CHAT_REQUEST:
                draft.syncNumberOfChatsStatus = createRequestStatus();
                break;
            case actions.SYNC_NUMBER_OF_OBJECT_CHAT_SUCCESS:
                draft.syncNumberOfChatsStatus = createSuccessStatus();
                draft.objectChats = action.data;
                break;
            case actions.SYNC_NUMBER_OF_OBJECT_CHAT_FAILURE:
                draft.syncNumberOfChatsStatus = createFailureStatus(action.error);
                break;
            case actions.POST_CHAT_REQUEST:
                draft.postChatStatus = createRequestStatus();
                break;
            case actions.POST_CHAT_SUCCESS:
                draft.postChatStatus = createSuccessStatus();
                break;
            case actions.POST_CHAT_FAILURE:
                draft.postChatStatus = createFailureStatus(action.error);
                break;
            case actions.DELETE_CHAT_REQUEST:
                draft.deleteChatStatus = createRequestStatus();
                break;
            case actions.DELETE_CHAT_SUCCESS:
                draft.deleteChatStatus = createSuccessStatus();
                break;
            case actions.DELETE_CHAT_FAILURE:
                draft.deleteChatStatus = createFailureStatus(action.error);
                break;
            case actions.POST_OBJECT_CHAT_REQUEST:
                draft.postObjectChatStatus = createRequestStatus();
                break;
            case actions.POST_OBJECT_CHAT_SUCCESS:
                draft.postObjectChatStatus = createSuccessStatus();
                break;
            case actions.POST_OBJECT_CHAT_FAILURE:
                draft.postObjectChatStatus = createFailureStatus(action.error);
                break;
            case actions.DELETE_OBJECT_CHAT_REQUEST:
                draft.deleteObjectChatStatus = createRequestStatus();
                break;
            case actions.DELETE_OBJECT_CHAT_SUCCESS:
                draft.deleteObjectChatStatus = createSuccessStatus();
                break;
            case actions.DELETE_OBJECT_CHAT_FAILURE:
                draft.deleteObjectChatStatus = createFailureStatus(action.error);
                break;
            case actions.RESET_DELETE_CHAT_STATUS:
                draft.deleteChatStatus = resetStatus();
                break;
            case actions.SYNC_NUMBER_OF_OBJECT_CHAT_DONE:
                draft.objectChats = [];
                draft.syncNumberOfObjectChatsStatus = resetStatus();
                break;
            case actions.SYNC_NUMBER_OF_CHAT_DONE:
                draft.syncNumberOfChatsStatus = resetStatus();
                break;
            case actions.LOAD_USER:
                try {
                    var user = window.sessionStorage.getItem("currentUser");
                    if (user) {
                        draft.currentUser = JSON.parse(user);
                    }
                }
                catch (_unused) {
                    console.log("Session Storage is disabled");
                }
                break;
            case actions.SAVE_USER:
                try {
                    draft.currentUser.name = action.name;
                    draft.currentUser.password = action.password;
                }
                catch (_unused2) {
                    console.log("Session Storage is disabled");
                }
                break;
            case actions.SAVE_AVATAR_USER:
            default: break;
        }
    });
};
export default reducer;
